<template>
<article class="terms-area">
	<h1>오케어 개인정보처리방침</h1>
	<h2>총칙</h2>
	<p>㈜케이비헬스케어의 KB 오케어서비스(이하 “KB 오케어”라 함)는 회원의 정보를 소중하게 생각하며 권리보호를 위해 , 「개인정보보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게
		개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보보호법」 제30조에 따라 회원에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게
		처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립&middot;공개합니다. </p>
	<section class="mt-x4">
		<h2>주요 개인정보 처리 표시(라벨링)</h2>
		<div class="terms-labeling">
			<div class="terms-labeling-item">
				<h3>개인정보 수집</h3>
				<p><span class="privacy-label" data-category="1"><span class="a11y-hidden">자동화 수집</span></span></p>
				<p>이름, 생년월일, 성별, 휴대폰번호, CI값 등</p>
			</div>
			<div class="terms-labeling-item">
				<h3>개인정보 처리목적</h3>
				<p><span class="privacy-label" data-process="2"><span class="a11y-hidden">처리목적</span></span></p>
				<p>회원식별 및 회원제서비스제공 등</p>
			</div>
			<div class="terms-labeling-item">
				<h3>개인정보 보유기간</h3>
				<p><span class="privacy-label" data-process="10"><span class="a11y-hidden">보유기간(설정)</span></span>
				</p>
				<p>회원탈퇴시 또는 법정의무보유기간만료시 등</p>
			</div>
			<div class="terms-labeling-item">
				<h3>개인정보 제3자 제공</h3>
				<p><span class="privacy-label" data-process="9"><span class="a11y-hidden">제3자 제공</span></span></p>
				<p>GC케어, 홈핏 등</p>
			</div>
			<div class="terms-labeling-item">
				<h3>개인정보처리위탁</h3>
				<p><span class="privacy-label" data-process="8"><span class="a11y-hidden">처리위탁</span></span></p>
				<p>유라클, 비즈마켓 등</p>
			</div>
			<div class="terms-labeling-item">
				<h3>개인정보 민원처리 담당부서</h3>
				<p><span class="privacy-label" data-requirement="9"><span class="a11y-hidden">고충처리부서</span></span>
				</p>
				<p>이메일: privacy@kbhc.co.kr</p>
				<p>전화: 02-2135-5062</p>
			</div>
		</div>
	</section>
	<section class="mt-x4">
		<h2>목차</h2>
		<p>KB 오케어는 회원의 개인정보를 보호하기 위하여 아래와 같이 개인정보처리방침을 가지고 있습니다.</p>
	</section>
	<hr>

	<section>
		<input type="checkbox" title="열기" id="acc1" class="a11y-hidden">
		<div class="acco-tit">
			<h3>1. 개인정보의 처리목적, 수집항목, 보유 및 이용기간</h3>
			<div class="privacy-label-wrap">
				<span class="privacy-label" data-category="1"><span class="a11y-hidden">자동화 수집</span></span>
				<span class="privacy-label" data-process="2"><span class="a11y-hidden">처리목적</span></span>
				<span class="privacy-label" data-process="1"><span class="a11y-hidden">처리항목</span></span>
				<span class="privacy-label" data-process="10"><span class="a11y-hidden">보유기간</span></span>
			</div>
			<label data-acco="acc1" for="acc1"><span class="a11y-hidden">열기</span></label>
		</div>
		<div class="acco-pnl" data-acco="acc1">
			<ol class="list-base" data-number="base" data-shape="round">
				<li>
					KB 오케어에서 처리한 개인정보는 다음의 목적 이외의 용도로 사용되지 않으며 이용 목적이 변경되는 경우에도 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를
					이행합니다.
					<div class="tbl-wrap">
						<table class="c-c-l-c">
							<caption>서비스, 수집 &middot; 이용 목적, 개인정보 수집 &middot; 이용 항목, 보유 및 이용기간 정보 테이블</caption>
							<colgroup>
								<col style="width:15%">
								<col style="width:20%">
								<col style="width:45%">
								<col style="width:20%">
							</colgroup>
							<thead>
								<tr>
									<th scope="col">서비스</th>
									<th scope="col">수집 &middot; 이용 목적</th>
									<th scope="col">개인정보 수집 &middot; 이용 항목</th>
									<th scope="col">보유 및 이용기간</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="rowgroup">회원사 관리</th>
									<th scope="row">기업 담당자 정보 관리</th>
									<td>
										<p>[필수] 담당자명, 담당자 휴대폰번호</p>
										<p>[선택] 없음</p>
									</td>
									<td>위탁계약종료시</td>
								</tr>
								<tr>
									<th scope="rowgroup">입점사 관리</th>
									<th scope="row">입점사 담당자 정보 관리</th>
									<td>
										<p>[필수] 담당자명, 담당자 휴대폰번호</p>
										<p>[선택] 없음</p>
									</td>
									<td>계약 종료시</td>
								</tr>
								<tr>
									<th rowspan="8" scope="rowgroup">회원 관리</th>
									<th scope="row">B2B 회원사 회원 정보 등록</th>
									<td>
										<p>[필수] 이름, 사번, 회사이메일 또는 이름, 휴대폰번호(이메일)</p>
										<p>[선택] 없음</p>
										<p>* 회원사 별 수집하는 정보 일부 상이 할 수 있음</p>
									</td>
									<td>위탁계약종료시</td>
								</tr>
								<tr>
									<th scope="row">회원사 회원 인증</th>
									<td>
										<p>[필수] 이름, 사번, 회사이메일 또는 이름, 휴대폰번호(이메일)</p>
										<p>[선택] 없음</p>
										<p>* 회원사 별 수집하는 정보 일부 상이 할 수 있음</p>
									</td>
									<td class="tc">계약 종료 및 퇴사시</td>
								</tr>
								<tr>
									<th scope="row">휴대폰 본인확인</th>
									<td>
										<p>[필수] 이름, 생년월일, 성별, 휴대폰번호, CI값</p>
										<p>[선택] 없음</p>
									</td>
									<td class="tc">본인확인후 파기</td>
								</tr>
								<tr>
									<th scope="row">불량회원의 부정이용 방지와 비인가 사용 방지, 중복가입 방지</th>
									<td>
										<p>[필수] CI값</p>
										<p>[선택] 없음</p>
									</td>
									<td class="tc">탈퇴 1년후 파기</td>
								</tr>
								<tr>
									<th scope="row">내정보관리(프로필설정)</th>
									<td>
										<p>[필수] 이름, 생년월일, 성별, 이메일, 휴대폰번호, 신체정보(키, 몸무게)</p>
										<p>[선택] 없음</p>
									</td>
									<td rowspan="30" class="tc">회원 탈퇴 시<br>*다만, 관계법령에 의해 보존할 경우 그 의무기간 동안별도 보관</td>
								</tr>
								<tr>
									<th scope="row">고객상담 및 불만처리 등 민원처리</th>
									<td>
										<p>[필수] 이름, 휴대폰번호, 이메일</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">(유료포함)서비스 이용시
										알림서비스</th>
									<td>
										<p>[필수] 이름, 휴대폰번호, 이메일</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">회원가입 및 관리</th>
									<td>
										<p>[필수] 이름, 생년월일, 성별, 휴대폰번호, CI값</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="rowgroup" rowspan="23">건강관리서비스</th>
									<th scope="row">건강예측 및 기록 등 건강관리 서비스 제공</th>
									<td>
										<p>[필수] 이름, 기타 의료적 요인(의사권고사항 행위여부), 생활습관(금연여부), 혈압수치, 혈당수치, 체중, 콜레스테롤 수치, 걸음수, 식사, 허리둘레,
											심박, 복약, 운동, 수면</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">건강뉴스 알림서비스</th>
									<td>
										<p>[필수] 이름, 대사질환, 나의식습관, 생활패턴</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">서비스 홍보 및 <br>광고성 정보 제공</th>
									<td>
										<p>[필수] 없음</p>
										<p>[선택] 이름, 이메일, 휴대폰번호</p>
									</td>
								</tr>
								<tr>
									<th scope="row">(스포애니)헬스장 이용상태 표시 및 출석확인서비스</th>
									<td>
										<p>[필수] 가입ID, 이용권 상품코드, 이용권상태(시작일, 종료일), 사용자 출석이력</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">(휴노)나의 심리상담 결과 및 진행상태 확인서비스 </th>
									<td>
										<p>[필수] 이름, 생년월일, 성별, 휴대폰번호, 이메일, 선택한 상담센터, 선택한 상담사, 상담예정일, 상담접수지정보, 만족도 평가, 심리검사 결과</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">(휴레이포지티브)체중 및 만성질환관리 조회서비스</th>
									<td>
										<p>[필수] 체중관리(기초대사량, 근육량, 체지방, BMI, 체수분, 체중, 내장지방, 단백질, 골량, 신체나이), 만성질환관리(기초설문조사, 기타
											건강정보, 디바이스 측정정보)</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">(탱크램팩토리)스마트로프를 통한 운동데이터 조회 및 랭킹정보 </th>
									<td>
										<p>[필수] 이름, 연령대, 연동데이터(줄넘기횟수)</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">(테라젠헬스)유전자 검사결과 조회서비스</th>
									<td>
										<p>[필수] 이름, 휴대폰번호, 이메일, 유전자 검사 결과, 건강문진표</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">(케어닥)간병서비스 이용현황 조회서비스</th>
									<td>
										<p>[필수] 이름, 생년월일, 이메일, 성별, 휴대폰번호</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">(비컨)두피 진단 결과 확인 및 분석기반 맞춤 솔루션제공서비스</th>
									<td>
										<p>[필수] 이름, 성별, 연령대 , 탈모유형</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">(일반검진)건강검진결과 조회 및 검진결과 기반 건강정보/증진서비스 제공</th>
									<td>
										<p>[필수] 이름, 검진기관명, 검진일, 신체계측[신장, 체중,
											허리둘레, 체질량지수(BMI), 수축/이완기혈압],
											혈액검사[혈색소, 공복시 혈당(FBS), 순환기(총콜레스테롤, HDL-콜레스테롤, 중성지방(TG), 신장요로(크레아티닌,신사구체여과율)]<br>* 정보주체
											별 수집하는 정보가 상이할 수 있음
										</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">(종합검진) 건강검진결과 조회 및 검진결과 기반 건강정보/증진서비스 제공</th>
									<td>
										<p>[필수] 없음</p>
										<p>[선택] 이름, 생년월일, 휴대폰번호, 검진기관명, 검진결과[신체계측(신장, 체중, 허리둘레, 비만도), 체성분, 초음파, 위장내시경, 골밀도, 폐질환,
											혈압, 시력, 안저 및 안압측정, 청력, A형/B형간염 항체검사, 간장질환 혈액검사, 일반혈액질환 혈액검사, 중성지방 혈액검사, 심전도검사, 당뇨질환
											혈액검사, 신장질환혈액검사, 요산, 류마티스 인자 혈액검사, 암표지자검사, 매독검사, 뇨침사검경, 잠혈검사, 갑상선질환검사, 부인과 검사,
											구강검사]<br>* 정보주체 별 수집하는 정보가 상이할 수 있음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">[KB국민은행 온국민 건강적금Ⅱ업무제휴] 건강검진 예약 및 결과 조회서비스 제공</th>
									<td>
										<p>[필수] 이름, 생년월일, 휴대폰번호, 검진기관명, 검진결과[신체계측(신장, 체중, 허리둘레, 비만도), 체성분, 초음파, 위장내시경, 골밀도, 폐질환,
											혈압, 시력, 안저 및 안압측정, 청력, A형/B형간염 항체검사, 간장질환 혈액검사, 일반혈액질환 혈액검사, 중성지방 혈액검사, 심전도검사, 당뇨질환
											혈액검사, 신장질환혈액검사, 요산, 류마티스 인자 혈액검사, 암표지자검사, 매독검사, 뇨침사검경, 잠혈검사, 갑상선질환검사, 부인과 검사,
											구강검사]<br>
											* 정보주체 별 수집하는 정보가 상이할 수 있음</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">1:1문의에 대한 답변 및 문의내역 관리 및 조회</th>
									<td>
										<p>[필수] 문의유형, 이름, 회원사, 답변결과</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">(KB국민은행 본점부속병원)<br>대면진료예약 및 조회</th>
									<td>
										<p>[필수] 이름, 휴대폰번호, 병원명, 희망진료일시, 증상, 진료완료여부</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">(KB국민은행 본점부속병원)<br>비대면진료예약 및 조회</th>
									<td>
										<p>[필수] 이름, 휴대폰번호, 병원명, 희망진료일시, 증상</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">KB건강검진 상담예약 및 조회</th>
									<td>
										<p>[필수] 이름, 휴대폰번호, 진료희망일시, 상담유형, 상담시 요청사항</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">올라케어 비대면 진료예약서비스(올라케어 자동 회원가입)</th>
									<td>
										<p>[필수] 이름, 휴대폰번호, 생년월일, 성별, 이메일, CI값</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">증상 유사도 분석 서비스</th>
									<td>
										<p>[필수] 이름, 성별, 증상 유사도 분석 결과</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">검진독려 및 오픈안내사항 전달</th>
									<td>
										<p>[필수] 이름, 휴대폰번호</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">AI추천 및 맞춤형 코칭 서비스</th>
									<td>
										<p>[필수] 이름, 나이, 성별, 문진결과(보유질환여부, 복용약 및 가족질병여부, 주량, 흡연여부, 운동횟수)</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
								<tr>
									<th scope="row">결과제공동의 안내 및 서식발송</th>
									<td>
										<p>[필수] 없음</p>
										<p>[선택] 이름, 이메일</p>
									</td>
								</tr>
								<tr>
									<th scope="row">검진이후 결과(유소견 등)에 대한 건강컨텐츠 발송</th>
									<td>
										<p>[필수] 없음</p>
										<p>[선택] 이메일</p>
									</td>
								</tr>
								<tr>
									<th scope="rowgroup" rowspan="25">결제서비스</th>
									<th scope="row">대금결제/환불서비스제공, 주문/배송/거래 내역조회서비스 제공</th>
									<td>
										<p>[필수] 이름. 휴대폰번호, 주문/배송/거래(결제)내역</p>
										<p>[선택] 없음</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</li>
				<li>
					개인정보 수집 방법
					<div>
						<p>- 모바일APP(회원가입, 경품응모, 배송요청 등)에서 수집</p>
						<p>- 회원사 및 입점사로부터 수집</p>
						<p>- 서비스 이용과정 자동 생성되는 정보</p>
					</div>
				</li>
				<li>KB 오케어는 만 14세 이상의 이용자에 한해서 서비스(회원가입 등)를 제공하는 것을 원칙으로 합니다.</li>
				<li>
					관련 법령에 의한 개인정보 보관
					<div class="tbl-wrap scrollX-no">
						<table>
							<caption>'수집항목, 이용목적, 근거법률, 보유기간' 정보 테이블</caption>
							<colgroup>
								<col style="width:25%">
								<col style="width:38%">
								<col style="width:auto">
								<col style="width:17%">
							</colgroup>
							<thead>
								<tr>
									<th scope="col">수집항목</th>
									<th scope="col">이용목적</th>
									<th scope="col">근거법률</th>
									<th scope="col">보유기간</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row" class="tc">접속일, 접속IP, 접속기기</th>
									<td>수사기관제공(법원 영장 등 정당한 절차의 경우에 제공)</td>
									<td class="tc">통신비밀보호법</td>
									<td class="tc">3개월</td>
								</tr>
								<tr>
									<th scope="row" class="tc">회원식별정보, 분쟁처리기록, 계약 철회기록</th>
									<td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
									<td rowspan="2" class="tc">전자상거래법</td>
									<td class="tc">3년</td>
								</tr>
								<tr>
									<th scope="row" class="tc">계약 및 철회 기록, 대금결제 기록</th>
									<td>대금 결제 및 재화 등의 공급에 관한 기록<br>계약 또는 철회 등에 관한 기록</td>
									<td class="tc">5년</td>
								</tr>
								<tr>
									<th scope="row" class="tc">위도, 경도 좌표</th>
									<td>위치정보취급대장</td>
									<td class="tc">위치정보보호법</td>
									<td class="tc">6개월</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p>※ 소득세법에 따라 경품 수령을 위한 제세공과금 처리 목적으로 회원의 주민등록번호, 계좌번호 및 주소 정보를 수집할 수 있습니다.(시행규칙 제100조 제25호)</p>
				</li>
				<li>
					서비스 이용과정에서 자동으로 생성되는 정보
					<div class="tbl-wrap scrollX-no">
						<table>
							<caption>'수집 및 이용목적, 수집 항목, 보유기간' 정보 테이블</caption>
							<colgroup>
								<col style="width:33%">
								<col style="width:34%">
								<col style="width:33%">
							</colgroup>
							<thead>
								<tr>
									<th scope="col">수집 및 이용목적</th>
									<th scope="col">수집 항목</th>
									<th scope="col">보유기간</th>
								</tr>
							</thead>
							<tbody class="tc">
								<tr>
									<th scope="row">불량회원의 부정이용 방지와 비인가 사용 방지, 중복가입방지</th>
									<td>본인확인값(CI)</td>
									<td>탈퇴 1년후 파기</td>
								</tr>
								<tr>
									<th scope="row">서비스 이용 통계 </th>
									<td>모바일기기OS 정보(OS종류 및 버전, 디바이스종류)</td>
									<td>회원 탈퇴 시 또는 법정 의무 보유기간까지</td>
								</tr>
							</tbody>
						</table>
					</div>
				</li>
				<li>
					모바일 앱 서비스를 위하여 아래와 같이 이용자의 이동통신단말기 내 정보 및 기능에 접근, 이용할 수 있습니다. 모바일 앱 서비스에서 요청하는 모든 요청권한은 선택적 접근
					권한으로 선택 접근항목은 해당 서비스 최초 이용 시 별도 동의를 받습니다. 선택 접근항목의 경우 OS버전에 따라 동의 받는 방법이 다를 수 있으나, 회원이 거부하더라도 기본
					서비스 이용에는 제한이 없습니다.
					<div class="tbl-wrap">
						<table>
							<caption>구분, 접근권한, 접근항목, 이용목적, 비고의 테이블</caption>
							<colgroup>
								<col style="width:15%">
								<col style="width:10%">
								<col style="width:20%">
								<col style="width:40%">
								<col style="width:15%">
							</colgroup>
							<thead>
								<tr>
									<th scope="col">구분</th>
									<th scope="col">접근권한</th>
									<th scope="col">접근항목</th>
									<th scope="col">이용목적</th>
									<th scope="col">비고</th>
								</tr>
							</thead>
							<tbody class="tc">
								<tr>
									<th scope="row">AOS/iOS</th>
									<td>선택</td>
									<td>파일 및 미디어 (사진 및 동영상)</td>
									<td>마이헬스 건강기록(식단기록), 프로필 이미지등록</td>
									<td rowspan="10">※ 선택적 접근권한은 동의하지 않아도 기본 서비스 이용이 가능</td>
								</tr>
								<tr>
									<th scope="row">AOS/iOS</th>
									<td>선택</td>
									<td>근처기기(블루투스)</td>
									<td>마이헬스 건강기록 장비연동 (체중계, 혈압계, 당뇨계, 줄넘기)</td>
								</tr>
								<tr>
									<th scope="row">AOS/iOS</th>
									<td>선택</td>
									<td>마이크</td>
									<td>제휴사 사용(상담, 영양제 맞춤설계를 위한 약사 화상면담)</td>
								</tr>
								<tr>
									<th scope="row">AOS/iOS</th>
									<td>선택</td>
									<td>위치</td>
									<td>병원 및 약국찾기</td>
								</tr>
								<tr>
									<th scope="row">AOS/iOS</th>
									<td>선택</td>
									<td>카메라</td>
									<td>마이헬스 건강기록(식단기록, 복약관리를 위한 약봉투/처방전 스캔 등), 프로필 이미지 등록, 제휴사 사용(영양제 맞춤설계를 위한 약사 화상면담)</td>
								</tr>
								<tr>
									<th scope="row">AOS/iOS</th>
									<td>선택</td>
									<td>건강정보/신체활동</td>
									<td>마이헬스 건강기록(플랫폼 건강정보연동)</td>
								</tr>
								<tr>
									<th scope="row">AOS/iOS</th>
									<td>선택</td>
									<td>알림</td>
									<td>건강뉴스 등 서비스 이용시 알림 수신</td>
								</tr>
								<tr>
									<th scope="row">iOS</th>
									<td>선택</td>
									<td>페이스ID/지문</td>
									<td>사용자 생체인증</td>
								</tr>
								<tr>
									<th scope="row">AOS</th>
									<td>필수</td>
									<td>전화</td>
									<td>단말기정보 및 네트워크 확인</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p>※ KB 오케어 서비스 앱 접근권한 동의 또는 철회 방법</p>
					<div>
						<p>- 안드로이드 계열 : 설정→애플리케이션→ ‘KB 오케어’ 앱 선택 → 권한 → 접근 항목별 ‘허용’ 또는 ‘허용 안함’ 체크</p>
						<p>- iOS계열 : 설정 → ‘KB 오케어’ 앱 선택 → 접근권한 별 동의 또는 철회 체크</p>
					</div>
				</li>
			</ol>
		</div>
	</section>
	<hr>

	<section>
		<input type="checkbox" title="열기" id="acc2" class="a11y-hidden">
		<div class="acco-tit">
			<h3>2. 개인정보의 제3자제공</h3>
			<div class="privacy-label-wrap">
				<span class="privacy-label" data-process="9"><span class="a11y-hidden">개인정보의 3자제공</span></span>
				<span class="privacy-label" data-process="7"><span class="a11y-hidden">국외이전</span></span>
			</div>
			<label data-acco="acc2" for="acc2"><span class="a11y-hidden">열기</span></label>
		</div>
		<div class="acco-pnl" data-acco="acc2">
			<ol class="list-base" data-number="base" data-shape="round">
				<li>
					KB 오케어는 정보주체의 동의, 법률의 특별한 규정 등 개인정보보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
					<div>
						<p>- 이용자가 서비스 이용중 제3자 제공에 동의한 경우</p>
					</div>
					<div class="tbl-wrap">
						<input type="checkbox" id="acco2_1" class="a11y-hidden">
						<input type="checkbox" id="acco2_2" class="a11y-hidden">
						<input type="checkbox" id="acco2_3" class="a11y-hidden">
						<input type="checkbox" id="acco2_4" class="a11y-hidden">
						<table>
							<caption>'제공받는자, 제공 목적, 제공하는 항목, 보유 및 이용기간' 정보 테이블</caption>
							<colgroup>
								<col style="width:20%">
								<col style="width:28%">
								<col style="width:auto">
								<col style="width:12%">
							</colgroup>
							<thead>
								<tr>
									<th scope="col">제공받는자</th>
									<th scope="col">제공 목적</th>
									<th scope="col">제공하는 항목</th>
									<th scope="col">보유 및 이용기간</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row" class="tc">㈜테라젠헬스</th>
									<td>
										1. 유전자 검사 분석/상담서비스 및 키트 배송<br>
										2. 청약의사의 확인, 거래이행, 배송, 회원상담 및 AS불만처리<br>
										3. 1:1 문의 및 답변
									</td>
									<td>
										1. 유전자 검사 분석/상담서비스 및 키트 배송<br>
										- 이름, 생년월일, 휴대폰번호, 이메일, 주소<br>
										2. 청약의사의 확인, 거래이행, 배송, 회원상담 및 AS불만처리<br>
										- 이름, 휴대폰번호, 주문/거래(결제)내역<br>
										3. 1:1 문의<br>
										- 이름, 휴대폰번호, 문의내용
									</td>
									<td rowspan="21">이용목적 달성 시</td>
								</tr>
								<tr>
									<th scope="row" class="tc">㈜휴노</th>
									<td>
										1. 대면상담서비스<br>
										2. 비대면상담서비스<br>
										3. 6종 심리검사 및 결과확인서비스<br>
										4. 6종심리검사/결과확인+상담(대면)서비스<br>
										5. 6종심리검사/결과확인+상담(비대면)서비스<br>
										6. 주민상품예약, 고객상담 및 불만처리
									</td>
									<td>
										1. 대면상담서비스<br>
										- 이름, 생년월일, 성별, 휴대폰번호, 이메일, 선택한 상담센터, 상담희망예정일, 상담주제, 상담요청사항<br>
										2. 비대면상담서비스(음성, 채팅)<br>
										- 이름, 생년월일, 성별, 휴대폰번호, 이메일, 선택한 상담사, 상담희망예정일, 상담접수지정보, 만족도평가<br>
										3. 6종 심리검사 및 결과확인서비스 <br>
										- 이름, 생년월일, 성별, 휴대폰번호, 이메일, 연령, 심리검사 결과<br>
										4. 6종심리검사/결과확인+상담(대면)서비스<br>
										- 이름, 생년월일, 성별, 휴대폰번호, 이메일, 연령, 심리검사 결과, 선택한 상담센터, 상담희망예정일<br>
										5. 6종심리검사/결과확인+상담(음성, 채팅)서비스<br>
										- 이름, 생년월일, 성별, 휴대폰번호, 이메일, 연령, 심리검사 결과, 선택한 상담사, 상담희망예정일, 상담접수지정보, 만족도 평가<br>
										6. 주민상품예약, 고객상담 및 불만처리<br>
										- 이름, 휴대폰번호, 주문/거래(결제)내역
									</td>
								</tr>
								<tr>
									<th scope="row" class="tc">㈜휴레이포지티브</th>
									<td>
										1. 체중관리서비스<br>
										2. 만성질환관리서비스<br>
										3. 청약의사의 확인, 거래이행, 배송, 회원상담 및 AS불만처리<br>
										4. 1:1문의 및 답변
									</td>
									<td>
										1. 체중관리서비스<br>
										- 이름, 휴대폰번호, 주문/거래(결제)내역, 기초대사량 근육량, 체지방, BMI, 체수분, 체중, 내장지방, 단백질, 골량, 신체나이<br>
										2. 만성질환관리서비스<br>
										- 이름, 성별, 생년월일, 핸드폰번호, 기초설문조사(심뇌혈관 위험도, 건강관리, 식습관, 개선의지 및 식습관 목표), 기타 건강정보(신장, 체중, 혈압,
										식사, 운동, 걸음수, 수면, 다바이스 측정정보(혈당, 혈압, 맥박, 체성분))<br>
										3. 1:1문의 및 답변<br>
										- 이름, 휴대폰번호, 문의내용
									</td>
								</tr>
								<tr>
									<th scope="row" class="tc">㈜GC케어</th>
									<td>건강검진 예약/평가/결과확인 서비스</td>
									<td>생년월일, 사번 또는 생년월일, 이메일</td>
								</tr>
								<tr>
									<th scope="row" class="tc">㈜케어닥</th>
									<td>
										간병서비스 제공 및 1:1문의답변 등
									</td>
									<td>이름, 생년월일, 성별, 이메일, 휴대폰번호, 문의내용</td>
								</tr>
								<tr>
									<th scope="row" class="tc">㈜사운드짐</th>
									<td>
										홈트레이닝 운동영상 컨텐츠 제공 및 1:1문의답변 등
									</td>
									<td>이름, 휴대폰번호, 주문/거래(결제)내역, 문의내용</td>
								</tr>
								<tr>
									<th scope="row" class="tc">㈜케이디스포츠</th>
									<td>
										1. 스포애니 회원가입여부확인/헬스장 이용서비스 제공 및 1:1문의답변 등

									</td>
									<td>
										- 이름, 휴대폰번호, 이용권(쿠폰)상품코드, 주문/거래 (결제)내역, 문의내용
									</td>
								</tr>
								<tr>
									<th scope="row" class="tc">㈜홈핏</th>
									<td>
										1.전문가 매칭을 통한 홈핏서비스 제공 및 1:1문의답변 등
									</td>
									<td>이름, 휴대폰번호, 주문/거래내역, 성별, 문의내용</td>
								</tr>
								<tr>
									<th scope="row" class="tc">㈜탱그램팩토리</th>
									<td>
										1. 스마트로프 서비스 제공 및 1:1문의답변 등
									</td>
									<td>주문자(이름, 휴대폰번호), 수령자(이름, 휴대폰번호), 주소, 주문/거래내역, 문의내용</td>
								</tr>
								<tr>
									<th scope="row" class="tc">KB국민은행 부속병원</th>
									<td>대면/비대면 진료 및 건강검진상담</td>
									<td>
										이름, 휴대폰번호, 희망진료일시, 증상(건강검진상담), 건강검진 결과(신체계측, 혈액검사 등)<br>
										* 정보주체 별 제공하는 정보가 상이할 수 있음
									</td>
								</tr>
								<tr>
									<th scope="row" class="tc">㈜참핏</th>
									<td>P스트레칭서비스 및 1:1문의답변 등</td>
									<td>이름, 휴대폰번호, 거래내역, 문의내용</td>
								</tr>
								<tr>
									<th scope="row" class="tc">한스킨, 팽귀니즘, 더페이스앤바디, 프롬더네이처, 라움다움테라피</th>
									<td>아로마 테라피서비스 및 1:1문의답변 등</td>
									<td>이름, 휴대폰번호, 거래 내역, 문의내용</td>
								</tr>
								<tr>
									<th scope="row">
										<div class="acco-tit">
											<p class="tc">참손길 지압 힐링센터 업체</p>
											<label data-acco="b3" for="acco2_3"><span class="a11y-hidden">열기</span></label>
										</div>
									</th>
									<td>안마서비스 및 1:1문의답변 등</td>
									<td>이름, 휴대폰번호, 거래 내역, 문의내용</td>
								</tr>
								<tr class="acco-pnl" data-acco="b3">
									<td colspan="3">
										<div class="company-wrap">
											<span>참손길지압힐링센터(강남점),</span> <span>참손길지압힐링센터(사당점),</span> <span>참손길지압힐링센터(선릉점),</span>
											<span>참손길지압힐링센터(여의도점),</span> <span>참손길지압힐링센터(수원역점),</span>
											<span>참손길지압힐링센터(인덕원점),</span> 
											<span>참손길지압힐링센터(마곡점),</span> <span>참손길지압힐링센터(수원광교점),</span>
											<span>참손길지압힐링센터(부천점),</span> <span>참손길지압힐링센터(광명점)</span>
										</div>
									</td>
								</tr>
								<tr>
									<th scope="row" class="tc">㈜킥더허들</th>
									<td>맞춤영양제(핏타민) 구독서비스 1:1문의답변 등</td>
									<td>이름, 이메일, 거래내역, 문의내용</td>
								</tr>
								<tr>
									<th scope="row" class="tc">
										<div class="acco-tit">
											<p class="tc">골프제휴업체</p>
											<label data-acco="b4" for="acco2_4"><span class="a11y-hidden">열기</span></label>
										</div>
									</th>
									<td>골프 서비스 및 1:1 문의답변 등</td>
									<td>이름, 휴대폰번호, 거래내역, 문의내용</td>
								</tr>
								<tr class="acco-pnl" data-acco="b4">
									<td colspan="3">
										<div class="company-wrap">
											<span>(주)마침,</span> <span>여의도골프아카데미,</span> <span>(주)샤인플레이,</span>
											<span>에이스타골프,</span> <span>최인자골프연습장,</span> <span>이지내방 골프연습장,</span>
											<span>(주)큐이디,</span> <span>레인보우일팔칠이(서여의도점),</span> <span>레인보우일팔칠이(동여의도점)</span>
										</div>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<div class="acco-tit">
											<p class="tc">배송업체</p>
											<label data-acco="b1" for="acco2_1"><span class="a11y-hidden">열기</span></label>
										</div>
									</th>
									<td class="tc">물품배송</td>
									<td>주문자(이름, 휴대폰번호), 수령자(이름, 휴대폰번호), 주소, 상품구매정보, 문의내용</td>
								</tr>
								<tr class="acco-pnl" data-acco="b1">
									<td colspan="3">
										<div class="company-wrap">
											<span>㈜인바디,</span> <span>㈜현대그린푸드,</span> <span>㈜메디쏠라,</span> <span>(주)72스포츠,</span>
											<span>(주)거성디지털,</span> <span>(주)골피아,</span> <span>(주)구문,</span> <span>(주)그린라이프,</span>
											<span>(주)글루업,</span> <span>(주)나래비앤씨,</span> <span>(주)나비스토리,</span>
											<span>(주)네이처프라임,</span> <span>(주)네츄럴메이드,</span> <span>(주)넥서스더마켓,</span>
											<span>(주)노블스컴,</span> <span>(주)노이타보니,</span> <span>(주)뉴비즈원,</span>
											<span>(주)뉴테나,</span> <span>(주)뉴트리원,</span> <span>(주)뉴트리케어,</span>
											<span>(주)다원스토리,</span> <span>(주)닥터헬퍼,</span> <span>(주)대동씨엔지,</span>
											<span>(주)대아,</span> <span>(주)대운비앤엘,</span> <span>(주)더그라운드컴퍼니,</span>
											<span>(주)더봄,</span> <span>(주)두버,</span> <span>(주)드림리테일,</span>
											<span>(주)디에이블커머스,</span> <span>(주)라온리테일,</span> <span>(주)라인에스앤씨,</span>
											<span>(주)로그온커머스,</span> <span>(주)로이첸,</span> <span>(주)루킨,</span>
											<span>(주)루트마케팅,</span> <span>(주)메디쿼터스,</span> <span>(주)미래바이텍,</span>
											<span>(주)베넷트레이드,</span> <span>(주)벨바이오,</span> <span>(주)비앤에프,</span>
											<span>(주)비앤케이글로벌,</span> <span>(주)비즈엠넷,</span> <span>(주)비즈엠넷코리아,</span>
											<span>(주)비컨,</span> <span>(주)빌리버스,</span> <span>(주)삼신이앤비,</span>
											<span>(주)상상공간그룹,</span> <span>(주)서로가치,</span> <span>(주)서신인터내셔널,</span>
											<span>(주)세윤티에스티,</span> <span>(주)소울트라,</span> <span>(주)슈리엘컴퍼니,</span>
											<span>(주)심플리빙,</span> <span>(주)씨너지아이앤티,</span> <span>(주)씨와이인터내셔널,</span>
											<span>(주)아란코리아,</span> <span>(주)아름다운프로,</span> <span>(주)아에르,</span>
											<span>(주)아이몰피엠지,</span> <span>(주)아이센스비즈,</span> <span>(주)아이엔리테일,</span>
											<span>(주)아이원세븐,</span> <span>(주)아인코퍼레이션,</span> <span>(주)애니온넷,</span>
											<span>(주)어썸퓨어,</span> <span>(주)에드가인터내셔날,</span> <span>(주)에스앤엘컴,</span>
											<span>(주)에스에이치인터내셔널,</span> <span>(주)에스엘더블유,</span> <span>(주)에스와이솔루션 청주지사,</span>
											<span>(주)에이스바이옴,</span> <span>(주)에이이에이치코리아,</span> <span>(주)에이젯코리아,</span>
											<span>(주)에이치앤에프,</span> <span>(주)에이파파,</span> <span>(주)에프엔디,</span>
											<span>(주)엘비에쓰,</span> <span>(주)엠투글로벌,</span> <span>(주)온누리스토어,</span>
											<span>(주)와이다,</span> <span>(주)와이비크롬,</span> <span>(주)와이앤제이인프라,</span>
											<span>(주)와이엔에스커머스,</span> <span>(주)와이제이컴퍼니,</span> <span>(주)와이즈셀렉션,</span>
											<span>(주)우성레포츠,</span> <span>(주)웅진식품,</span> <span>(주)유니온코리아,</span>
											<span>(주)유니템아이,</span> <span>(주)유비코퍼레이션,</span> <span>(주)유한건강생활,</span>
											<span>(주)이고진,</span> <span>(주)이루자,</span> <span>(주)이바이오테코,</span>
											<span>(주)이스트헬스케어,</span> <span>(주)이알아이,</span> <span>(주)이픽월드,</span>
											<span>(주)인트리홀딩스,</span> <span>(주)인피니티프로,</span> <span>(주)잇서,</span>
											<span>(주)자연과사람들,</span> <span>(주)재승,</span> <span>(주)제니엘맥,</span>
											<span>(주)제로베이스,</span> <span>(주)제이더블유아이앤씨,</span> <span>(주)제이디코퍼레이션,</span>
											<span>(주)제이엔씨,</span> <span>(주)제이와이더블유인터내셔날,</span> <span>(주)제이이티,</span>
											<span>(주)쥐쥐디,</span> <span>(주)지룩,</span> <span>(주)지비컴퍼니,</span>
											<span>(주)지앤티글로벌,</span> <span>(주)지엠몰,</span> <span>(주)지원솔루션,</span>
											<span>(주)진영글로비즈,</span> <span>(주)진인프라,</span> <span>(주)케이디헬스케어,</span>
											<span>(주)케이비엔,</span> <span>(주)케이스코,</span> <span>(주)케이앤비컴퍼니,</span>
											<span>(주)케이제이코퍼레이션,</span> <span>(주)코너스톤204,</span> <span>(주)탱그램팩토리,</span>
											<span>(주)테라비즈,</span> <span>(주)투엘컴퍼니,</span> <span>(주)투와이트레이더스,</span>
											<span>(주)트렉스타,</span> <span>(주)티아이엠앤코,</span> <span>(주)티에스윈,</span>
											<span>(주)티에이피,</span> <span>(주)티엔케이,</span> <span>(주)티제이넷,</span>
											<span>(주)티케이마켓,</span> <span>(주)티켓몰,</span> <span>(주)파인트리,</span>
											<span>(주)풍성에프에이,</span> <span>(주)프로템,</span> <span>(주)피엘컴퍼니,</span>
											<span>(주)해피존,</span> <span>(주)헬스맥스,</span> <span>(주)헬스하우스,</span>
											<span>(주)혜성인터내셔널,</span> <span>(주)혜인건강,</span> <span>(주)홀라이프,</span>
											<span>(주)휘인테크,</span> <span>CJ프레시웨이,</span> <span>KM통상,</span> <span>PA 인터내셔널,</span>
											<span>SDF인터내셔널,</span> <span>건강식품연구원,</span> <span>광동생활건강(주),</span>
											<span>나무와사람들,</span> <span>네이비베이(주),</span> <span>네이처가든,</span> <span>넥스트인프라,</span>
											<span>농협홍삼,</span> <span>뉴페이스인터내셔널,</span> <span>다래커머스,</span> <span>다우,</span>
											<span>두이커머스(주),</span> <span>두일지엘(주),</span> <span>드보르코리아,</span>
											<span>디엔케이네트웍스,</span> <span>땡큐폰,</span> <span>띠앗,</span> <span>라이프샐러드(주),</span>
											<span>래안어스,</span> <span>로니웰,</span> <span>리얼스핀(주),</span> <span>리얼화이트,</span>
											<span>리즈메디,</span> <span>링크비,</span> <span>맘앤인펀트,</span> <span>매일헬스뉴트리션,</span>
											<span>메가트레이드,</span> <span>메디스턴,</span> <span>메이저월드(주),</span>
											<span>보령컨슈머헬스케어㈜,</span> <span>봉봉컴퍼니,</span> <span>비에프케이,</span> <span>비즈라인,</span>
											<span>비즈마켓,</span> <span>비타믹스㈜,</span> <span>산들봄,</span> <span>삼지인터내셔널,</span>
											<span>세라젬,</span> <span>솔트리,</span> <span>아모레퍼시픽,</span> <span>아이캐치,</span>
											<span>아침마을,</span> <span>알로코리아(주),</span> <span>에덴룩스,</span> <span>에쓰앤씨코퍼레이션,</span>
											<span>에이에스지,</span> <span>엘케이리테일㈜,</span> <span>오아(주),</span> <span>올코스,</span>
											<span>와이제이컴퍼니,</span> <span>웰빙하우스,</span> <span>웰스리믹,</span> <span>위더스네이처,</span>
											<span>유앤아이텍(주),</span> <span>유케이특판(주),</span> <span>이지셀biz,</span> <span>이층엔약국,</span>
											<span>일넷코리아,</span> <span>정관장청주시청점,</span> <span>정관장홍삼금호점,</span> <span>제이디커머스,</span>
											<span>제이투컴퍼니,</span> <span>젠코리아코퍼레이션,</span> <span>㈜에스엔티코프,</span>
											<span>주우리유통㈜,</span> <span>㈜이니스리테일,</span> <span>지티플러스,</span> <span>진프론티어,</span>
											<span>천호엔케어,</span> <span>커넥팅피앤비,</span> <span>케이앤씨플러스,</span> <span>쿱스엔터프라이즈,</span>
											<span>큐브디컴퍼니(주),</span> <span>크리비스컨설팅,</span> <span>킥더허들헬스케어,</span> <span>탑30,</span>
											<span>태남생활건강(주),</span> <span>테라바디코리아유한회사,</span> <span>트라이스,</span>
											<span>파인디지털,</span> <span>팬텀코리아,</span> <span>포드몰,</span> <span>풀무원건강생활,</span>
											<span>프롬바이오,</span> <span>플라워스완,</span> <span>플랜비,</span> <span>플로우링크,</span>
											<span>피데아커머스(주),</span> <span>한국생활건강,</span> <span>한산피앤지(주),</span>
											<span>한스글로벌(주),</span> <span>한신프로젝트(주),</span> <span>허니골프수원권선점,</span>
											<span>현대리바트,</span> <span>호랑이건강원에프앤비(주),</span> <span>휴먼플러스</span>
										</div>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<div class="acco-tit">
											<p class="tc">회원이 지정한 검진예약기관</p>
											<label data-acco="b2" for="acco2_2"><span class="a11y-hidden">열기</span></label>
										</div>
									</th>
									<td class="tc">건강검진 예약</td>
									<td>이름, 생년월일, 휴대폰번호, 이메일, 검진병원, 검진희망예약일, 주소, 선택한 검사항목[기본검사 (신체계측, 안과검사, 체성분, 치과검사, 일반혈액검사,
										매독검사, 혈중지질관계, 간기능생화학검사), 추천 검사]</td>
								</tr>
								<tr class="acco-pnl" data-acco="b2">
									<td colspan="3">
										<div class="company-wrap">
											<span>HS한신메디피아, </span>
											<span>녹십자아이메드 강남센터, </span>
											<span>메디플라워헬스케어, </span>
											<span>서울메디케어, </span>
											<span>서울부민병원, </span>
											<span>강남KMI, </span>
											<span>강남하트스캔, </span>
											<span>리더스헬스케어, </span>
											<span>미래의료재단, </span>
											<span>비에비스 나무병원, </span>
											<span>차병원 차움검진센터 삼성분원, </span>
											<span>여의도KMI, </span>
											<span>서울성애병원, </span>
											<span>순천향대학교 서울병원, </span>
											<span>광화문KMI, </span>
											<span>세브란스체크업 서울역, </span>
											<span>서울중앙의료의원, </span>
											<span>우리원헬스케어, </span>
											<span>노원을지대학교병원, </span>
											<span>서울척병원, </span>
											<span>나사렛국제병원, </span>
											<span>인천바로병원, </span>
											<span>인천세종병원, </span>
											<span>명지병원, </span>
											<span>부천세종병원, </span>
											<span>남천병원, </span>
											<span>메디피움 분당, </span>
											<span>분당 필립메디컬센터, </span>
											<span>아주대학교병원, </span>
											<span>수원KMI, </span>
											<span>수지미래의원 건강검진센터, </span>
											<span>성베드로병원, </span>
											<span>평택굿모닝병원, </span>
											<span>인성병원, </span>
											<span>성지병원, </span>
											<span>강릉동인병원, </span>
											<span>강릉아산병원, </span>
											<span>한국건강관리협회 충북세종지부, </span>
											<span>서산중앙병원, </span>
											<span>한국건강관리협회 충남지부, </span>
											<span>삼영의료재단 둔산병원, </span>
											<span>대전중앙의료재단CMI, </span>
											<span>유성선병원, </span>
											<span>광주KS병원, </span>
											<span>한국건강관리협회 광주전남지부, </span>
											<span>한국건강관리협회 전북지부, </span>
											<span>전주내과, </span>
											<span>익산원광대학교병원, </span>
											<span>목포한국병원, </span>
											<span>순천한국병원, </span>
											<span>성가롤로병원, </span>
											<span>구미강남병원, </span>
											<span>안동성소병원, </span>
											<span>포항성모병원, </span>
											<span>한국건강관리협회 울산광역지부, </span>
											<span>울산중앙병원, </span>
											<span>대구KMI, </span>
											<span>대경영상의학과 종합검진센터, </span>
											<span>이샘병원, </span>
											<span>한국건강관리협회 부산광역지부, </span>
											<span>부산KMI, </span>
											<span>부산미래IFC 검진센터, </span>
											<span>한국건강관리협회 경상남도지부, </span>
											<span>진주제일병원, </span>
											<span>한국건강관리협회 제주자치지부</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<p>- 개인정보를 이용자의 동의없이 제공한 경우</p>
					<div class="tbl-wrap scrollX-no">
						<table>
							<caption>'제공받는자, 제공 목적, 제공하는 항목, 보유 및 이용기간' 동의없이 제공한 정보 테이블</caption>
							<colgroup>
								<col style="width:20%">
								<col style="width:40%">
								<col style="width:auto">
								<col style="width:20%">
							</colgroup>
							<thead>
								<tr>
									<th scope="col">제공받는자</th>
									<th scope="col">제공 목적</th>
									<th scope="col">제공하는 항목</th>
									<th scope="col">보유 및 이용기간</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row" class="tc">KB국민은행</th>
									<td>
										KB온국민건강적금 우대금리 확인<br>(KB오케어가입여부)
									</td>
									<td class="tc">
										CI값
									</td>
									<td class="tc">이용 목적 달성 시</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p>* “추가적인 이용 및 제공 기준”에 의한 합리적인 판단에 따라 정보주체의 동의없이 추가 제공할 수 있습니다.</p>
					<div>
						<p>가) 당초 수집 목적과 관련성이 있는지 여부</p>
						<p>나) 개인정보를 수집한 정황, 처리 관행에 비추어 볼 때 개인정보의 추가적인 제공에 대한 예측 가능성이 있는지 여부</p>
						<p>다) 정보주체의 이익을 부당하게 침해하는지 여부</p>
						<p>라) 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부</p>
					</div>
				</li>
			</ol>
		</div>
	</section>
	<hr>

	<section>
		<input type="checkbox" title="열기" id="acc3" class="a11y-hidden">
		<div class="acco-tit">
			<h3>3. 개인정보의 처리위탁</h3>
			<div class="privacy-label-wrap">
				<span class="privacy-label" data-process="8"><span class="a11y-hidden">처리위탁</span></span>
			</div>
			<label data-acco="acc3" for="acc3"><span class="a11y-hidden">열기</span></label>
		</div>
		<div class="acco-pnl" data-acco="acc3">
			<ol class="list-base" data-number="base" data-shape="round">
				<li>KB 오케어는 서비스 향상을 위하여 개인정보를 제한된 범위에서 업체에 위탁하여 관리하도록 할 수 있습니다.</li>
				<li>
					KB 오케어는 관계 법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고 있습니다. 또한, 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이
					본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
					<div class="tbl-wrap scrollX-no">
						<table>
							<caption>'수탁자, 서비스 내용, 보유 및 이용기간' 정보 테이블</caption>
							<colgroup>
								<col style="width:30%;">
								<col style="width: auto;">
								<col style="width: auto;">
							</colgroup>
							<thead>
								<tr>
									<th scope="col">수탁자</th>
									<th scope="col">서비스 내용</th>
									<th scope="col">보유 및 이용기간</th>
								</tr>
							</thead>
							<tbody class="tc">
								<tr>
									<th scope="row">㈜유라클</th>
									<td>O’CARE 개발 및 유지보수</td>
									<td>위탁 계약 종료시</td>
								</tr>
								<tr>
									<th scope="row">Microsoft Azure</th>
									<td>데이터보관</td>
									<td>위탁 계약 종료시</td>
								</tr>
								<tr>
									<th scope="row">코리아크레딧뷰로(KCB)</th>
									<td>본인확인서비스</td>
									<td>위탁 계약 종료시</td>
								</tr>
								<tr>
									<th scope="row">㈜케이지이니시스</th>
									<td>온라인결제서비스</td>
									<td>관련법령에 따른 5년 보존 후 파기</td>
								</tr>
								<tr>
									<th scope="row">㈜비즈마켓</th>
									<td>커머스 플랫폼 운영 및 개발 유지보수</td>
									<td>위탁 계약 종료시</td>
								</tr>
								<tr>
									<th scope="row">㈜에임메드</th>
									<td>건강 검진 및 증진서비스</td>
									<td>위탁 계약 종료시</td>
								</tr>
								<tr>
									<th scope="row">㈜카카오엔터프라이즈(재수탁사 :넥서스커뮤니티)</th>
									<td>녹취서비스 제공(재수탁업무 : 녹취시스템 운영 및 유지보수)</td>
									<td>위탁 계약 종료시</td>
								</tr>
								<tr>
									<th scope="row">㈜GC케어</th>
									<td>KB 온국민건강적금 가입자를 위한 건강검진서비스 제공</td>
									<td>위탁 계약 종료시</td>
								</tr>
							</tbody>
						</table>
					</div>
				</li>
			</ol>
		</div>
	</section>
	<hr>

	<section>
		<input type="checkbox" title="열기" id="acc4" class="a11y-hidden">
		<div class="acco-tit">
			<h3>4. 개인위치정보의 처리</h3>
			<div class="privacy-label-wrap">
				<span class="privacy-label" data-category="5"><span class="a11y-hidden">파기</span></span>
			</div>
			<label data-acco="acc4" for="acc4"><span class="a11y-hidden">열기</span></label>
		</div>
		<div class="acco-pnl" data-acco="acc4">
			<ol class="list-base" data-number="base" data-shape="round">
				<li>KB 오케어는 위치정보의 보호 및 이용 등에 관한 법률(이하 “위치정보법”)에 따라 다음과 같이 개인위치정보를 처리합니다.</li>
				<li>
					개인위치정보의 처리목적 및 보유기간
					<div class="tbl-wrap scrollX-no">
						<table>
							<caption>'서비스명, 처리목적, 보유기간' 정보 테이블</caption>
							<colgroup>
								<col style="width: 33%;">
								<col style="width: auto;">
								<col style="width: auto;">
							</colgroup>
							<thead>
								<tr>
									<th scope="col">서비스명</th>
									<th scope="col">처리목적</th>
									<th scope="col">보유기간</th>
								</tr>
							</thead>
							<tbody class="tc">
								<tr>
									<td>약국 및 병원찾기</td>
									<td>위치정보수집 대상의 실시간 위치확인<br>이용자의 위치에서 근접한 약국 및 병원 정보 제공</td>
									<td>일회성 또는 임시로 이용 후 지체없이 파기</td>
								</tr>
							</tbody>
						</table>
					</div>
				</li>
				<li>개인위치정보 수집·이용·제공 사실 확인자료의 보유근거 및 보유기간<br>KB 오케어는 위치정보법 제16조2항에 근거하여 이용자의 위치정보 수집·이용·제공사실 확인자료를
					위치정보시스템에 자동으로 기록하며, 6개월간 보관합니다.</li>
				<li>
					개인위치정보의 파기 절차 및 방법
					<div>
						<p>- 종이에 출력된 개인 정보 : 분쇄기로 분쇄하거나 소각</p>
						<p>- 전자적 파일 형태로 저장된 개인 정보 : 기록을 재사용할 수 없는 기술적 방법을 사용하여 삭제</p>
					</div>
				</li>
				<li>
					개인위치정보의 제3자 제공 및 통보에 관한 사항<br>
					KB 오케어는 이용자의 사전 동의없이 개인위치정보를 제3자에게 제공하지 않고 있습니다. 단, 이용자가 지정한 제3자에게 제공하는 경우에는 매회 이용자에게 제공받는 자, 제공일시
					및 제공목적을 즉시 통보합니다.
				</li>
				<li>
					법 제25조 제1항에 따른 보호의무자의 권리<br>
					<div>
						<p>- KB 오케어는 다음에 해당하는 자의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가
							있는 것으로 봅니다.</p>
						<div>
							<p>&middot; 8세 이하의 아동</p>
							<p>&middot; 피성년후견인</p>
							<p>&middot; 장애인복지법 제2조 제2항 제2호에 따른 정신적 장애를 가진 자로서 장애인고용촉진 및 직업재활법 제2조제2호에 따른 중증장애인에 해당하는
								자(장애인복지법 제32조에 따라 장애인 등록을 한 자만 해당)</p>
						</div>
						<p>- 8세 이하의 아동의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을
							첨부하여 회사에 제출하여야 하며, 이 경우 보호의무자는 개인위치정보 주체의 권리를 모두 가집니다.</p>
					</div>
				</li>
				<li>
					위치정보관리책임자의 정보
					<div class="tbl-wrap scrollX-no">
						<table>
							<caption>'구분, 부서, 이름, 전자우편, 연락처' 정보 테이블</caption>
							<colgroup>
								<col style="width:25%;">
								<col style="width:25%;">
								<col style="width:25%;">
								<col style="width:25%;">
								<col style="width:25%;">
							</colgroup>
							<thead>
								<tr>
									<th scope="col">구분</th>
									<th scope="col">부서</th>
									<th scope="col">이름</th>
									<th scope="col">전자우편</th>
									<th scope="col">연락처</th>
								</tr>
							</thead>
							<tbody class="tc">
								<tr>
									<td>위치정보관리책임자</td>
									<td>대표이사</td>
									<td>최낙천</td>
									<td>privacy@kbhc.co.kr</td>
									<td>02-2135-5062</td>
								</tr>
							</tbody>
						</table>
					</div>
				</li>
			</ol>
		</div>
	</section>
	<hr>

	<section>
		<input type="checkbox" title="열기" id="acc5" class="a11y-hidden">
		<div class="acco-tit">
			<h3>5. 개인정보의 파기 절차 및 방법</h3>
			<div class="privacy-label-wrap">
				<span class="privacy-label" data-process="11"><span class="a11y-hidden">파기</span></span>
			</div>
			<label data-acco="acc5" for="acc5"><span class="a11y-hidden">열기</span></label>
		</div>
		<div class="acco-pnl" data-acco="acc5">
			<ol class="list-base" data-number="base" data-shape="round">
				<li>KB 오케어는 개인정보보호책임자의 책임 하에 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</li>
				<li>정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
					외부와 차단된 별도 DB 또는 테이블에 분리하여 보관합니다.</li>
				<li>
					장기 미이용자 개인정보 파기<br>
					<div>
						<p>1) 회사는 장기간(5 년이상) 서비스 미이용자의 개인정보 보호를 위하여 개인정보를 파기(회원탈퇴 처리)합니다.</p>
						<p>2) 미이용의 기간은 최종 로그인 일자, 휴면 해제일 등의 기준으로 산정됩니다.</p>
						<p>3) 회사는 미이용자 개인정보를 파기하는 시점 도래 1 개월 이전에 이메일 등을 통하여 해당 이용자에게 관련내용을 공지합니다.</p>
					</div>
				</li>
				<li>
					개인정보 파기의 방법은 다음과 같습니다.
					<p>- 종이에 출력된 개인 정보 : 분쇄기로 분쇄하거나 소각</p>
					<p>- 전자적 파일 형태로 저장된 개인 정보 : 기록을 재사용할 수 없는 기술적 방법을 사용하여 삭제</p>
				</li>
			</ol>
		</div>
	</section>
	<hr>

	<section>
		<input type="checkbox" title="열기" id="acc6" class="a11y-hidden">
		<div class="acco-tit">
			<h3>6. 이용자의 권리&middot;의무 및 행사방법</h3>
			<div class="privacy-label-wrap">
				<span class="privacy-label" data-requirement="1"><span class="a11y-hidden">권리의무</span></span>
				<span class="privacy-label" data-requirement="11"><span class="a11y-hidden">열람청구</span></span>
			</div>
			<label data-acco="acc6" for="acc6"><span class="a11y-hidden">열기</span></label>
		</div>
		<div class="acco-pnl" data-acco="acc6">
			<ol class="list-base" data-number="base" data-shape="round">
				<li>
					이용자는 개인정보 보호법 제35조에 따른 개인정보의 열람&middot;정정&middot;처리정지&middot;삭제 청구를 이메일, 전화, 방문 등의 방법으로 아래의 부서에 할
					수 있습니다. 회사는 이용자의 개인정보 열람&middot;정정&middot;처리정지&middot;삭제 청구가 신속하게 처리되도록 노력하겠습니다.
					<div class="tbl-wrap scrollX-no">
						<table>
							<caption>'개인정보 민원처리 담당부서' 정보 테이블</caption>
							<thead>
								<tr>
									<th scope="col">개인정보 민원처리 담당부서</th>
								</tr>
							</thead>
							<tbody class="tc">
								<tr>
									<td>정보보호 파트</td>
								</tr>
								<tr>
									<td>이메일: privacy@kbhc.co.kr</td>
								</tr>
								<tr>
									<td>전화: 02-2135-5062</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p>다만, 근무 시간 이후 또는 주말 및 공휴일에는 익일 처리하는 것을 원칙으로 합니다.</p>
				</li>
				<li>권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 처리방법에 관한 고시 별지 제11호 서식에 따른 위임장을
					제출하셔야 합니다.</li>
				<li>
					이용자의 권리에 따른 열람의 요구, 정정&middot;삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한자가 본인이거나 정당한 대리인인지를 확인합니다.
					<div>
						<p>[개인정보 처리방법에 관한 고시 별지 제8호] 개인정보 열람 요구서</p>
						<p>[개인정보 처리방법에 관한 고시 별지 제11호] 위임장</p>
					</div>
				</li>
				<li>개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 이용자의 권리가 제한될 수 있습니다.</li>
				<li>이용자의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게
					있으며 타인 정보의 도용 등 허위 정보를 입력할 경우 회원 자격이 상실될 수 있습니다.</li>
				<li>이용자는 언제든지 『마이페이지』 메뉴를 통해 자신의 개인정보를 조회하거나 수정할 수 있습니다.</li>
			</ol>
		</div>
	</section>
	<hr>

	<section>
		<input type="checkbox" title="열기" id="acc7" class="a11y-hidden">
		<div class="acco-tit">
			<h3>7. 개인정보의 자동 수집장치의 설치·운영 및 거부에 관한 사항</h3>
			<div class="privacy-label-wrap">
				<span class="privacy-label" data-process="4"><span class="a11y-hidden">자동화 수집</span></span>
			</div>
			<label data-acco="acc7" for="acc7"><span class="a11y-hidden">열기</span></label>
		</div>
		<div class="acco-pnl" data-acco="acc7">
			<p>KB 오케어는 서비스 이용과정에서 이용자로부터 다음과 같은 정보들이 자동으로 생성&middot;수집되고 다음의 목적으로 이용될 수 있습니다.</p>
			<div class="tbl-wrap">
				<table>
					<caption>'개인정보 자동수집정보 사용목적' 정보 테이블</caption>
					<thead>
						<tr>
							<th scope="col">개인정보 자동수집정보 사용목적</th>
						</tr>
					</thead>
					<tbody>
						<tr class="tc">
							<td>KB 오케어는 관련법규의 준수를 위해 이용자의 접속기록(로그인)기록을 보관할 의무가 있습니다.<br>KB 오케어는 이용자의 이용기록을 분석하여 서비스 개선
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="tbl-wrap">
				<table>
					<caption>'개인정보 자동수집안내' 정보 테이블</caption>
					<thead>
						<tr>
							<th scope="col">개인정보 자동수집안내</th>
						</tr>
					</thead>
					<tbody>
						<tr class="tc">
							<td>앱을 통해 서비스를 실행 시 기기식별 고유번호(예: 장치 ID 등), 단말기 정보를 자동으로 수집하게 됩니다. 이용자가 기기식별 고유번호를 자동으로 수집하는 것을
								거부하는 경우 회사에서 제공한 서비스를 이용하실 수 없습니다.</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="tbl-wrap">
				<table>

					<caption>'개인정보 자동수집 거부방법(예시)' 정보 테이블</caption>
					<thead>
						<tr>
							<th scope="col">개인정보 자동수집 거부방법(예시)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								&middot; Internet Explorer의 경우: 웹 브라우저 상단의 도구 메뉴 > 인터넷 옵션 > 개인정보 > 설정<br>
								&middot; Chrome의 경우: 웹 브라우저 우측의 설정 메뉴 > 화면 하단의 고급 설정 표시 > 개인정보의 콘텐츠 설정 버튼 > 쿠키<br>
								&middot; Edge 를 사용하는 경우 : [설정 및 기타] 메뉴 > [설정] > [쿠키 및 사이트 권한] > [쿠키 및 사이트 데이터]<br>
								&middot; 안드로이드 폰: 휴대폰 설정 > 앱 관리> KB 오케어 > 저장소 > 사용자데이터/캐시 > 데이터 지우기<br>
								&middot; 아이폰: 설정 > 앱 관리 > KB 오케어 > 저장소 > 데이터 지우기
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</section>
	<hr>

	<section>
		<input type="checkbox" title="열기" id="acc8" class="a11y-hidden">
		<div class="acco-tit">
			<h3>8. 행태정보의 수집·이용·제공·거부사항</h3>
			<div class="privacy-label-wrap">
				<span class="privacy-label" data-process="5"><span class="a11y-hidden">행태정보의 수집</span></span>
			</div>
			<label data-acco="acc8" for="acc8"><span class="a11y-hidden">열기</span></label>
		</div>
		<div class="acco-pnl" data-acco="acc8">
			<ol class="list-base" data-number="base" data-shape="round">
				<li>KB 오케어는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제고하기 위하여 행태정보를 수집·이용하고 있습니다.
					<p>* 행태정보란 : 웹 사이트 방문 이력, 앱 사용 이력, 구매 및 검색 이력 등 이용자의 관심, 흥미, 기호 및 성향 등을 파악하고 분석할 수 있는 온라인상의 이용자
						활동정보</p>
				</li>
				<li>수집 및 이용하는 행태정보
					<div class="tbl-wrap">
						<table>
							<caption>수집 및 이용하는 행태정보 정보 테이블</caption>
							<thead>
								<tr>
									<th scope="col">수집하는 행태정보의 항목</th>
									<th scope="col">행태정보 수집 방법</th>
									<th scope="col">행태정보 수집 목적</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										이용자의 앱 서비스 방문기록, 검색/클릭 등의 사용기록, 이용자 식별값, 연령대, 성별, 디바이스 관련 정보(모델명, 제조사, 기기타입, 플랫폼, 설정된 언어
										및 지역, 운영체제 및 운영체제 버전), 타임존 정보, IP주소, 네트워크 정보, 웹브라우저 정보, 앱 버전)
									</td>
									<td>
										이용자가 앱 내에서 행해지는 주요행동에 대해 생성 정보 수집 툴(Hackle)을 통해 자동 수집<br>
										이용자 개인을 식별할 수 없도록 비식별화 처리된 익명의 사용자 정보를 이용
									</td>
									<td>
										상품·서비스의 속도 및 상태 개선<br>
										고객 서비스 이용 통계를 활용한 신상품·서비스 개발<br>
										사용자 행태정보 기반 광고 제공
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="tbl-wrap scrollX-no">
						<table>
							<caption>보유 및 이용기간 정보 테이블</caption>
							<thead>
								<tr>
									<th scope="col">보유 및 이용기간</th>
								</tr>
							</thead>
							<tbody>
								<tr class="tc">
									<td>
										<strong>수집일로부터 최대 1년간 보유·이용되며, 이후 지체없이 삭제</strong><br>
										보유기간 경과 후에는 비 식별화 조치 후 통계 업무 목적만을 위하여 보유<br>
										회사는 온라인 맞춤형 광고 등에 필요한 비식별화 처리된 최소한의 행태정보만을 수집하며, 사상, 신념, 가족 및 친인척관계, 학력·병력, 기타 사회활동 경력
										등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 민감한 행태정보를 수집하지 않습니다.
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="tbl-wrap scrollX-no">
						<table>
							<caption>수집 및 이용하는 행태정보 정보 테이블</caption>
							<thead>
								<tr>
									<th scope="col">이용자 통제권 행사방법</th>
									<th scope="col">이용자 피해구제 방법</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										안드로이드폰 : ① 구글설정 → ② 광고 → ③ 광고 맞춤설정 선택 또는 해제<br>
										아이폰 : ① 아이폰설정 → ② 개인정보보호 → ③ 광고 → ④ 광고 추적 제한 ※ OS버전에 따라 방법이 다소 상이할 수 있습니다.
									</td>
									<td>
										맞춤형 광고에 대한 문의 또는 불만 사항 등이 있으실 경우, 아래로 연락 주시면 적극적으로 대응 및 회신하겠습니다.<br>
										KB헬스케어 고객센터( 전화: 1544-3677)
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</li>
			</ol>
		</div>
	</section>
	<hr>

	<section>
		<input type="checkbox" title="열기" id="acc9" class="a11y-hidden">
		<div class="acco-tit">
			<h3>9. 개인정보의 안전성 확보조치</h3>
			<div class="privacy-label-wrap">
				<span class="privacy-label" data-requirement="2"><span class="a11y-hidden">안정성확보조치</span></span>
			</div>
			<label data-acco="acc9" for="acc9"><span class="a11y-hidden">열기</span></label>
		</div>
		<div class="acco-pnl" data-acco="acc9">
			<p>KB 오케어는 「개인정보보호법」 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.</p>
			<ol class="list-base" data-number="base" data-shape="round">
				<li>
					내부관리계획의 수립 및 시행<br>
					개인정보 내부관리계획 수립 및 시행은 ㈜케이비헬스케어의 내부관리 지침을 준수하여 시행합니다.
				</li>
				<li>
					개인정보 취급 담당자의 최소화 및 교육<br>
					개인정보를 취급하는 담당자를 지정하고 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.
				</li>
				<li>
					개인정보에 대한 접근 제한<br>
					개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여
					외부로부터의 무단 접근을 통제하고 있습니다.
				</li>
				<li>
					접속기록의 보관 및 위변조 방지<br>
					개인정보처리시스템에 접속한 기록을 최소 3년 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
				</li>
				<li>
					개인정보의 암호화<br>
					이용자의 개인정보는 암호화되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.
				</li>
				<li>
					해킹 등에 대비한 기술적 대책<br>
					KB 오케어는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신&middot;점검을 하며 외부로부터 접근이 통제된
					구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다. 또한 네트워크 트래픽의 통제(Monitoring)는 물론 불법적으로 정보를 변경하는 등의 시도를 탐지하고
					있습니다.
				</li>
			</ol>
		</div>
	</section>
	<hr>
	<section>
		<input type="checkbox" title="열기" id="acc10" class="a11y-hidden">
		<div class="acco-tit">
			<h3>10. 링크사이트에 대한 책임</h3>
			<label data-acco="acc10" for="acc10"><span class="a11y-hidden">열기</span></label>
		</div>
		<div class="acco-pnl" data-acco="acc10">
			<ol class="list-base" data-number="base" data-shape="round">
				<li>KB 오케어는 회원에게 다른 회사의 웹사이트(WEB View) 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 KB 오케어는 외부사이트와 자료에 대해 아무런 통제권이
					없으므로 해당 사이트(WEB View)에서 제공받는 서비스나 자료의 진실성, 유용성 등에 대해 책임지지 않으며, 어떠한 보증도 하지 않습니다.</li>
				<li>KB 오케어에서 포함하고 있는 링크를 클릭하여 다른 사이트(WEB View)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 회사와 무관하므로 새로 방문한 사이트의
					정책을 반드시 검토하시기 바랍니다.</li>
			</ol>
		</div>
	</section>
	<hr>
	<section>
		<input type="checkbox" title="열기" id="acc11" class="a11y-hidden">
		<div class="acco-tit">
			<h3>11. 광고성 정보 전송</h3>
			<label data-acco="acc11" for="acc11"><span class="a11y-hidden">열기</span></label>
		</div>
		<div class="acco-pnl" data-acco="acc11">
			<ol class="list-base" data-number="base" data-shape="round">
				<li>KB 오케어는 이용자의 명시적인 수신 거부 의사에 반하여 영리 목적의 광고성 정보를 전송하지 않습니다.</li>
				<li>
					KB 오케어는 이용자가 뉴스레터 등 전자 우편 전송에 대한 동의를 한 경우 전자 우편의 제목란 및 본문란에 다음 사항과 같이 이용자가 쉽게 알아볼 수 있도록 조치합니다.
					<div>
						<p>- 전자 우편의 제목란: 전자 우편 본문란의 주요 내용을 표시합니다.</p>
						<p>
							- 전자 우편의 본문란<br>
							가. 이용자가 수신 거부의 의사표시를 할 수 있는 전송 자의 명칭, 전자우편주소, 전화번호 및 주소를 명시합니다.<br>
							나. 이용자가 수신 거부의 의사를 쉽게 표시할 수 있는 방법을 한글 또는 영문으로 각각 명시합니다.<br>
							다. 이용자가 동의를 한 시기 및 내용을 명시합니다.
						</p>
					</div>
				</li>
				<li>팩스, 휴대폰 문자 전송 등 전자 우편 이외의 문자 전송을 통해 영리 목적의 광고성 정보를 전송하는 경우에는 전송 내용 처음에 "(광고)"라는 문구를 표기하고 전송 내용
					중에 전송 자의 연락처를 명시하도록 조치합니다.</li>
			</ol>
		</div>
	</section>
	<hr>
	<section>
		<input type="checkbox" title="열기" id="acc12" class="a11y-hidden">
		<div class="acco-tit">
			<h3>12. 권익침해 구제방법</h3>
			<div class="privacy-label-wrap">
				<span class="privacy-label" data-requirement="10"><span class="a11y-hidden">권익침해</span></span>
			</div>
			<label data-acco="acc12" for="acc12"><span class="a11y-hidden">열기</span></label>
		</div>
		<div class="acco-pnl" data-acco="acc12">
			<p>이용자는 아래의 기관에서 개인정보침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.</p>
			<div class="tbl-wrap">
				<table>
					<caption>'개인정보 침해신고센터, 개인정보분쟁조정위원회, 경찰청 사이버수사국, 대검찰청 사이버수사과' 정보 테이블</caption>
					<thead>
						<tr>
							<th scope="col">개인정보 침해신고센터</th>
							<th scope="col">개인정보분쟁조정위원회</th>
							<th scope="col">경찰청</th>
							<th scope="col">대검찰청</th>
						</tr>
					</thead>
					<tbody class="tc">
						<tr>
							<td>privacy.kisa.or.kr<br>(국번없이 118)</td>
							<td>www.kopico.go.kr<br>(국번없이 1833-6972)</td>
							<td>ecrm.cyber.go.kr<br>(국번없이 182)</td>
							<td>spo.go.kr<br>(국번없이 1301)</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</section>
	<hr>
	<section>
		<input type="checkbox" title="열기" id="acc13" class="a11y-hidden">
		<div class="acco-tit">
			<h3>13. 개인정보 보호책임자에 관한 사항</h3>
			<div class="privacy-label-wrap">
				<span class="privacy-label" data-requirement="7"><span class="a11y-hidden">보호책임자</span></span>
			</div>
			<label data-acco="acc13" for="acc13"><span class="a11y-hidden">열기</span></label>
		</div>
		<div class="acco-pnl" data-acco="acc13">
			<ol class="list-base" data-number="base" data-shape="round">
				<li>KB 오케어는 이용자가 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 이용자에게 고지한 사항들에 반하는 사고가 발생할 시에 그
					책임은 KB 오케어에 있습니다.</li>
				<li>그러나 기술적인 보완 조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물 내용에
					의한 각종 분쟁에 관해서는 KB 오케어는 책임을 지지 않습니다.</li>
				<li>이용자의 개인정보를 보호하는 책임자는 다음과 같으며 개인정보 관련 문의사항에 성실하게 답변해드리고 있습니다.
					<div class="tbl-wrap scrollX-no">
						<table>
							<caption>'구분, 이름, 전자우편, 연락처' 정보 테이블</caption>
							<thead>
								<tr>
									<th scope="col">구분</th>
									<th scope="col">이름</th>
									<th scope="col">전자우편</th>
									<th scope="col">연락처</th>
								</tr>
							</thead>
							<tbody class="tc">
								<tr>
									<td>개인정보 보호책임자</td>
									<td>최낙천</td>
									<td rowspan="2">privacy@kbhc.co.kr</td>
									<td rowspan="2">02-2135-5062</td>
								</tr>
								<tr>
									<td>개인정보 보호관리자</td>
									<td>배하림</td>
								</tr>
							</tbody>
						</table>
					</div>
				</li>
			</ol>
			
		</div>
	</section>
	<hr>
	<section>
		<input type="checkbox" title="열기" id="acc14" class="a11y-hidden">
		<div class="acco-tit">
			<h3>14. 개인정보처리방침의 변경</h3>
			<div class="privacy-label-wrap">
				<span class="privacy-label" data-requirement="3"><span class="a11y-hidden">처리방침변경</span></span>
			</div>
			<label data-acco="acc14" for="acc14"><span class="a11y-hidden">열기</span></label>
		</div>
		<div class="acco-pnl" data-acco="acc14">
			<ol class="list-base" data-number="base" data-shape="round">
				<li>공고 일자 : 2024년 11월 21일</li>
				<li>시행 일자 : 2024년 11월 27일</li>
			</ol>
		</div>
	</section>
</article>
</template>

<style scoped>
	* {box-sizing:border-box;}
	html{font-size: 62.5%;}
	body.cke_editable{padding:2rem; margin: 0; overflow-y: overlay;}
	.scroll-no {overflow: hidden;padding-right: 1.7rem; }
</style>

<script>
export default {
	name: 'PersonV19',
}
</script>